import React from "react";
import BEMHelper from "react-bem-helper";
import { useStaticQuery, graphql, Link } from "gatsby";
import LogoSVG from "../images/logo.svg";
import "./Header.scss";

const classNames = new BEMHelper("header");

const Header = () => {
  return (
    <header {...classNames(null, "large")}>
      <Link to="/" {...classNames("logo")}>
        <LogoSVG />
      </Link>
      <h1 {...classNames("headline")}>Rebecca Lehners</h1>
      <p {...classNames("subline")}>Autorin und Werbetexterin</p>
    </header>
  );
};

export default Header;
