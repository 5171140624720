import PropTypes from "prop-types";
import React from "react";
import BEMHelper from "react-bem-helper";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import "./Nav.scss";

const classNames = new BEMHelper("nav");

const Nav = ({ additionalClasses }) => {
  return (
    <nav {...classNames(null, null, additionalClasses)}>
      <ul {...classNames("list")}>
        <li {...classNames("item")}>
          <AnchorLink to="/#ueber-mich" {...classNames("link", "active")}>
            Über mich
          </AnchorLink>
        </li>
        <li {...classNames("item")}>
          <AnchorLink to="/#meine-buecher" {...classNames("link")}>
            Meine Bücher
          </AnchorLink>
        </li>
        <li {...classNames("item")}>
          <AnchorLink to="/#kontakt" {...classNames("link")}>
            Kontakt
          </AnchorLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
