import React from "react";
import BEMHelper from "react-bem-helper";
import Actions from "./Actions";
import { Link } from "gatsby";
import Obfuscate from "react-obfuscate";
import "./Footer.scss";
import FacebookSVG from "../images/facebook.svg";
import InstagramSVG from "../images/instagram.svg";

const classNames = new BEMHelper({ name: "footer", outputIsString: true });

const Footer = () => {
  return (
    <footer className={classNames()}>
      <section className={classNames("section", "nav")}>
        <Actions
          additionalClasses={classNames("actions")}
          style="special"
          actions={[
            {
              label: "Impressum",
              href: "/impressum",
              internal: true,
              style: "inverted",
              asModal: true,
            },
            {
              label: "Datenschutz",
              href: "/datenschutz",
              internal: true,
              style: "inverted",
              asModal: true,
            },
          ]}
        />
      </section>

      <section className={classNames("section", "address")}>
        <h1 className={classNames("header")}>Adresse</h1>
        <address>
          <p className={classNames("text")}>
            Inhaber: Rebecca Lehnersc/o <br />
            AutorenServices.de <br />
            Birkenallee 24 • 36037 Fulda • Deutschland
          </p>
        </address>
      </section>
      <section className={classNames("section", "mail")}>
        <h1 className={classNames("header")}>E-Mail</h1>
        <p className={classNames("text")}>
          <Obfuscate
            email="rebecca.lehners.autorin@gmx.de"
            headers={{
              subject: "Kontakt über www.rebecca-lehners.de",
              body: "Sehr geehrte Frau Lehners,",
            }}
            obfuscate={true}
          />
        </p>
      </section>
      <section className={classNames("section", "social-media")}>
        <ul className={classNames("iconlist", null, "icons")}>
          <li className={classNames("iconlist-item")}>
            <a
              target="_blank"
              href="https://www.facebook.com/rebecca.lehners.1"
              className={classNames("icon", "inverted")}
            >
              <FacebookSVG />
              <span className={classNames("icon-label")}></span>
            </a>
          </li>
          <li className={classNames("iconlist-item")}>
            <a
              target="_blank"
              href="https://www.instagram.com/rebecca_lehners_autorin/"
              className={classNames("icon", "inverted")}
            >
              <InstagramSVG />
              <span className={classNames("icon-label")}></span>
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
};

export default Footer;
