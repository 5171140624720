import React from "react";
import PropTypes from "prop-types";
import BEMHelper from "react-bem-helper";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Nav";
import "./DefaultPage.scss";

const classNames = new BEMHelper({
  name: "default-page",
  outputIsString: true,
});

const DefaultPage = ({ nav = true, children }) => (
  <div className={classNames()}>
    <div className={classNames("wrapper")}>
      <Header />
      {nav && <Nav additionalClasses={classNames("nav")} />}
      <main className={classNames("main")}>{children}</main>
      <Footer />
    </div>
  </div>
);

DefaultPage.propTypes = {
  children: PropTypes.node.isRequired,
  nav: PropTypes.bool,
};

export default DefaultPage;
