import PropTypes from "prop-types";
import React from "react";
import BEMHelper from "react-bem-helper";
import "./SectionHeader.scss";

const classNames = new BEMHelper("section-header");

const SectionHeader = ({
  headline,
  subline,
  xsAlignment,
  mdAlignment,
  lgAlignment,
}) => (
  <div
    {...classNames(null, {
      "align-xs-left": xsAlignment === "left" ? true : false,
      "align-xs-center": xsAlignment === "center" ? true : false,
      "align-xs-right": xsAlignment === "right" ? true : false,
      "align-md-left": mdAlignment === "left" ? true : false,
      "align-md-center": mdAlignment === "center" ? true : false,
      "align-md-right": mdAlignment === "right" ? true : false,
      "align-lg-left": lgAlignment === "left" ? true : false,
      "align-lg-center": lgAlignment === "center" ? true : false,
      "align-lg-right": lgAlignment === "right" ? true : false,
    })}
  >
    <h1 {...classNames("headline")}>{headline}</h1>
    {subline && <p {...classNames("subline")}>{subline}</p>}
  </div>
);

SectionHeader.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  xsAlignment: PropTypes.oneOf(["left", "center", "right"]),
  mdAlignment: PropTypes.oneOf(["left", "center", "right"]),
  lgAlignment: PropTypes.oneOf(["left", "center", "right"]),
};

export default SectionHeader;
