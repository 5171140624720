import PropTypes from "prop-types";
import React from "react";
import BEMHelper from "react-bem-helper";
import "./Section.scss";

const classNames = new BEMHelper("section");

const Section = ({ children, color, id, hasPadding = true }) => (
  <section
    id={id}
    {...classNames(null, {
      grey: color === "grey" ? true : false,
      padding: hasPadding,
    })}
  >
    <div {...classNames("content")}>{children}</div>
  </section>
);

Section.propTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf(["grey"]),
  children: PropTypes.node,
  hasPadding: PropTypes.bool,
};

export default Section;
