import PropTypes from "prop-types";
import React from "react";
import BEMHelper from "react-bem-helper";
import { Link } from "gatsby-plugin-modal-routing";
import "./Button.scss";

const classNames = new BEMHelper("button");

const Button = ({ href, style, asModal, internal, children }) => {
  return (
    <>
      {internal ? (
        <Link to={href} asModal {...classNames(null, style)}>
          {children}
        </Link>
      ) : (
        <a target="_blank" href={href} {...classNames(null, style)}>
          {children}
        </a>
      )}
    </>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.oneOf(["primary", "secondary", "inverted"]),
  asModal: PropTypes.bool,
  internal: PropTypes.bool,
};

export default Button;
