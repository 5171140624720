import PropTypes from "prop-types";
import React from "react";
import BEMHelper from "react-bem-helper";
import Button from "./Button";
import "./Actions.scss";

const classNames = new BEMHelper("actions");

const Actions = ({ actions, style, additionalClasses }) => {
  return (
    <ul {...classNames(null, style, additionalClasses)}>
      {actions &&
        actions.map((action, index) => (
          <li {...classNames("item")} key={"action_" + index}>
            <Button
              href={action.href}
              style={action.style}
              size={action.size}
              asModal={action.modal}
              internal={action.internal}
            >
              {action.label}
            </Button>
          </li>
        ))}
    </ul>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      style: PropTypes.oneOf(["primary", "secondary", "inverted"]),
      additionalClasses: PropTypes.string,
      asModal: PropTypes.bool,
      internal: PropTypes.bool,
    })
  ),
  style: PropTypes.oneOf(["stacked", "special", "fit"]),
  additionalClasses: PropTypes.string,
};

export default Actions;
